<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vg_mb_8 flexV">
        <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add" icon="el-icon-plus">新增 </el-button>
        <el-button type="danger" size="small" @click="delRow()" :disabled="!btn.delete" icon="el-icon-delete">删除 </el-button>
        <el-button type="info" size="small" class="vd_export" icon="el-icon-refresh-right" @click="refresh()"> 刷新 </el-button>
        <el-button type="success" size="small" class="vd_export" @click="pinvSynchFlag = true"> 同步老ERP</el-button>
        <div class="flexHV" style="margin-left: auto">
          <span>所有页实际收款金额合计:{{ sumActualPrice }}</span>
          <span>当页实际收款金额合计:{{ objectArrayReduce(tableData, 'actual_price') }}</span>
        </div>
      </div>
      <div class="vg_mb_8 flexV">
        <el-radio-group v-model="selectedRadio" size="small" @change="getTableData">
          <el-radio-button :label="0">报关</el-radio-button>
          <el-radio-button :label="1">我司结算-辅料</el-radio-button>
        </el-radio-group>
      </div>
      <search-table
        ref="multiTable"
        :data="tableData"
        :tableRowKey="'pinv_id'"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        @select="selectRows"
        @selection-change="handleSelectionChange"
        @select-all="selectRows"
        @row-dblclick="dbClickJp"
        @getTableData="getTableData"
        :page-size="50"
      >
        <template v-slot:status="scope">
          <div style="text-align: center">
            <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
              {{ helper.getStatusName(scope.row.status).name }}
            </el-tag>
          </div>
        </template>
      </search-table>
    </el-card>
    <el-dialog title="同步采购发票" :visible.sync="pinvSynchFlag" width="75.5%" @opened="getTableData2">
      <div class="vg_mb_8">
        收票日期：
        <el-date-picker
          class="vg_ml_8 vg_mr_8 topDateRangeSearch"
          size="small"
          v-model="dateRecdate"
          type="daterange"
          range-separator="至"
          start-placeholder="收票开始日期"
          end-placeholder="收票结束日期"
        />
        <br />
        付款日期：
        <el-date-picker
          class="vg_ml_8 vg_mr_8 topDateRangeSearch"
          size="small"
          v-model="datePayment"
          type="daterange"
          range-separator="至"
          start-placeholder="付款开始日期"
          end-placeholder="付款结束日期"
        />
        <el-button type="primary" size="small" class="vd_export" @click="getTableData2()"> 查询</el-button>
        <el-button type="info" size="small" class="vd_export" @click="synchRefresh()"> 刷新</el-button>
        <el-button type="success" size="small" class="vd_export" @click="synchInput()"> 确定导入</el-button>
      </div>
      <DynamicUTable
        ref="multiTable2"
        :table-data="tableData2"
        :columns="tableProperties2"
        v-loading="loadFlag2"
        :totalPage="totalPage2"
        :need-search="true"
        :need-pagination="true"
        @selection-change="handleSelectionChangeRich"
        @getTableData="getTableData2"
        :need-check-box="true"
      >
      </DynamicUTable>
    </el-dialog>
  </div>
</template>

<script>
import SearchTable from '@/components/table/SearchTableV2.vue';
import { tableProperties, tableProperties2 } from '@/views/FinanceManagement/PinvManage/pinv';
import { pinvAPI } from '@api/modules/pinv';
import { getNoCatch, postNoCatch } from '@api/request';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { cloneDeep } from 'lodash';
import UrlEncode from '@assets/js/UrlEncode';
import { objectArrayReduce } from '@assets/js/arrayUtils';

export default {
  name: 'PinvNewList',
  components: { DynamicUTable, SearchTable },
  data() {
    return {
      tableData: [],
      tableData2: [],
      tableProperties: tableProperties,
      tableProperties2: tableProperties2,
      loadFlag: true,
      loadFlag2: true,
      totalPage: 0,
      totalPage2: 0,
      multiSelection: [],
      multiSelectionRich: [],
      btn: {},
      pinvSynchFlag: false,
      dateRecdate: [],
      datePayment: [],
      sumActualPrice: '',
      selectedRadio: '0'
    };
  },
  mounted() {
    let { form_id, tran_no, podr_no } = this.$route.query;
    if (form_id) {
      this.$refs.multiTable.searchForm.pinv_id = Number(form_id);
    }
    if (tran_no) {
      this.$refs.multiTable.searchForm.tran_nos = tran_no;
    }
    if (podr_no) {
      this.$refs.multiTable.searchForm.podr_nos = podr_no;
    }
    this.getTableData();
  },
  methods: {
    objectArrayReduce,
    async getTableData() {
      await (this.loadFlag = true);
      let searchForm = cloneDeep(this.$refs.multiTable.searchForm);
      searchForm.order_type = this.selectedRadio;
      getNoCatch(pinvAPI.getAllPinvs, searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.sumActualPrice = data.sumActualPrice;
        this.btn = data.btn;
        this.loadFlag = false;
      });
    },
    //获取采购发票同步
    async getTableData2() {
      await (this.loadFlag2 = true);
      let searchForm = cloneDeep(this.$refs.multiTable2.searchForm);
      let [startTime, endTime] = this.dateRecdate || [];
      let [startTime2, endTime2] = this.datePayment || [];
      //付款日期
      searchForm.pinv_recdate_start = startTime?.getTime();
      searchForm.pinv_recdate_end = endTime?.getTime();
      //收票日期
      searchForm.payment_date_start = startTime2?.getTime();
      searchForm.payment_date_end = endTime2?.getTime();
      await getNoCatch(pinvAPI.getPinvListByRichErp, searchForm).then(({ data }) => {
        this.tableData2 = data.list;
        this.totalPage2 = data.total;
        this.loadFlag2 = false;
      });
    },
    //同步刷新
    synchRefresh() {
      this.dateRecdate = [];
      this.datePayment = [];
      this.$refs.multiTable2.resetFields();
      this.getTableData2();
    },
    //双击同步
    dbClickJp2(row) {
      //判断此单据是否已经同步过
      getNoCatch(pinvAPI.getPinvByAutoInc, { autoInc: row.autoInc }).then(data => {
        if (data.code === 0) {
          this.pinvSynchFlag = false;
          this.$router.push({
            name: 'PinvAdd',
            query: { perm_id: this.$route.query.perm_id },
            params: { syncData: row }
          });
        } else {
          this.$message.error(data.msg);
        }
      });
    },
    dbClickJp(row) {
      this.jump('/pinv_edit', {
        key: UrlEncode.encode(JSON.stringify({ perm_id: this.$route.query.perm_id, form_id: row.pinv_id }))
      });
    },
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    handleSelectionChangeRich(val) {
      this.multiSelectionRich = val;
    },
    synchInput() {
      if (this.multiSelectionRich.length > 10) {
        return this.$message.error('最多可选择十条数据同步！');
      }
      if (this.multiSelectionRich.length < 1) {
        return this.$message.warning('请至少选择一条数据');
      }
      postNoCatch(pinvAPI.addPinvListByRichMax, this.multiSelectionRich).then(data => {
        if (data.code === 0) {
          this.pinvSynchFlag = false;
          this.$message.success('同步成功！');
          this.refresh(); //刷新list
        } else {
          this.$message.error(data.msg);
        }
      });
    },
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    addRow() {
      this.jump(`/pinv_add?perm_id=${this.$route.query.perm_id}&order_type=${this.selectedRadio}`);
    },
    delRow() {
      if (this.multiSelection.length === 0) return this.$message.warning('请至少选择一条数据!');
      let ids = Array.from(this.multiSelection, ({ pinv_id }) => pinv_id);
      postNoCatch(pinvAPI.deletePinvByIds, { pinv_ids: ids }).then(() => {
        this.$message.success('删除成功!');
        this.refresh();
      });
    },
    refresh() {
      this.$refs.multiTable.resetFields();
      this.getTableData();
    }
  }
};
</script>

<style scoped></style>
